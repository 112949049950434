@import "variables";
@import "mixins";

/*****************************
 * Implementation of Typeform *
 *****************************/

.tf-v1-popover {
  left: 22px !important; /* move the popover to left */
  width: 200px; /* limit popover width */
}

.tf-v1-popover.open {
  @include pc {
    min-height: 500px !important;
    min-width: 800px !important;
  }

  @include sp {
    left: 0px !important; /* remove the popover open to left */
  }
}
.tf-v1-popover-button {
  left: 22px !important; /* move the button to left */
}

.tf-v1-sidetab-button-text {
  color: #fff !important;
}
.tf-v1-close-icon {
  color: #fff !important;
}

.tf-v1-popover-button-icon {
  height: 34px !important;
}
