@import "variables";
@import "mixins";

.root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.body {
  display: flex;
  flex-direction: column;
  @include pc() {
    padding-top: 80px;
  }
  @include sp() {
    padding-top: 0px;
  }
}

.scrollTop {
  position: fixed;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: $blue;
  cursor: pointer;
  @include pc() {
    right: 20px;
    bottom: 20px;
  }
  @include sp() {
    right: 10px;
    bottom: 10px;
  }
  &:before {
    content: "";
    position: absolute;
    width: 12px;
    height: 12px;
    right: 0;
    left: 0;
    bottom: 6px;
    margin: auto;
    border: 2px solid;
    border-color: white white transparent transparent;
    transform: rotate(-45deg);
  }
}

.env {
  position: fixed;
  right: 0;
  top: 0;
  width: 200px;
  line-height: 70px;
  font-size: 25px;
  font-weight: 500;
  color: white;
  text-align: center;
  letter-spacing: 2px;
  z-index: 9999;
  cursor: pointer;
  &.dev {
    background: rgba(230, 100, 100, 0.8);
  }
  &.local {
    background: rgba(0, 0, 0, 0.5);
  }
}

.appBannerWrap {
  margin: 0 0 80px;

  @include pc {
    width: $pc-width;
    margin: 0 auto 80px;
  }
}

.isAppBannerMargin {
  @include sp {
    margin-top: 61px;
  }
}
