@import "variables";
@import "mixins";

//===================================
// ヘッダー本体
//===================================
.headerWrapper {
  position: fixed;
  width: 100%;
  background-color: white;
  border-bottom: 1px solid $lightGray;
  z-index: 10;

  @include sp {
    position: static;
    z-index: 0;
  }
}

.isMarginTopForAppBanner {
  @include sp {
    margin-top: 60px;
  }
}

.headerBody {
  display: flex;
  @include pc() {
    display: flex;
    height: $pc-header-height;
    width: $pc-width;
    margin: auto;
  }
  @include sp() {
    height: $sp-header-height;
    padding: 15px 0px 15px 20px;
  }

  .catch {
    margin-left: 16px;

    .text {
      margin-top: $pc-header-height - 46px;
      font-size: 11px;
      line-height: 14px;
      font-weight: 300;
      color: $black;
    }
  }
}

//===================================
// ロゴ
//===================================
.headerLogo {
  margin-top: auto;
  margin-bottom: auto;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left;
  background-image: url("/static/svgs/header/logo.svg");

  @include pc() {
    height: 36px;
    width: 186px;
  }
  @include sp() {
    height: 100%;
    width: 147px;
  }
}

//===================================
// ヘッダーボタン
//===================================
.headerButtons {
  @include sp {
    display: none;
  }
  @include pc {
    display: flex;
    gap: 10px;
    margin: auto 15px auto auto;
    a {
      display: flex;
      height: 56px;
      width: 165px;
      border: 1px solid $lightGray;
      border-radius: 3px;
      line-height: 56px;
      font-size: 13px;
      span {
        margin-right: auto;
      }
      &:before {
        content: "";
        display: block;
        margin: auto 7px auto auto;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
      }
      &.station:before {
        height: 21px;
        width: 16px;
        background-image: url("/static/svgs/top/station.svg");
      }
      &.area:before {
        height: 22px;
        width: 18px;
        background-image: url("/static/svgs/top/area.svg");
      }
    }
  }
}

//===================================
// ヘッダーメニュー
//===================================
.headerMenus {
  display: flex;
  align-items: center;
  @include sp {
    margin-left: auto;
    margin-right: 4px;
  }
}

.headerMenuItem {
  text-align: center;

  @include pc {
    width: 100px;
  }

  @include sp {
    width: 55px;
    border: none;
  }

  &.relative {
    position: relative;
  }

  .savedConditionPopup {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -100px;
    border-radius: 10px;
    background-color: $white;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.141);

    &::before {
      position: absolute;
      top: -22px;
      left: 50%;
      z-index: 1;
      width: 22px;
      height: 22px;
      font-size: 20px;
      pointer-events: none;
      content: "";
      background: $white;
      transform: translateX(-50%) translateY(50%) rotate(-135deg);
    }

    .innerWrap {
      position: relative;
      padding: 18px 28px;
      white-space: nowrap;

      .title {
        font-size: 14px;
        font-weight: 600;
        line-height: 21px;
        color: $blue;
      }

      .description {
        font-size: 12px;
        line-height: 18px;
      }

      .iconWrap {
        position: absolute;
        top: 4px;
        right: 4px;

        @include pc {
          transition: opacity $hover-transition;
          &:hover {
            cursor: pointer;

            opacity: $hover-opacity;
          }
        }
      }
    }
  }
}

.headerMenuItemLink {
  display: block;
  background-color: $white;
  border: none;
  margin: auto;
  padding: 0;

  &:hover {
    cursor: pointer;
  }
}

.icon {
  display: block;
}

.iconText {
  display: inline-block;
  font-size: 12px;
  line-height: 1;
  margin-top: 8px;

  @include sp {
    margin-top: 4px;
    font-size: 10px;
  }
}
