@import "variables";
@import "mixins";

.notificationBanner {
  width: 100%;
  text-decoration: underline;
  background-color: $superLightBlue;
  color: $blue;
  text-align: center;
  @include pc {
    height: 39px;
    line-height: 39px;
    font-size: 15px;
  }
  @include sp {
    height: 34px;
    line-height: 34px;
    font-size: 12px;
  }
}
