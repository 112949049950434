@import "variables";
@import "mixins";

.bannerContainer {
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 12px;
  width: 100%;
  padding: 20px 0;
  border-top: 1px solid $lightGray;
  border-radius: 20px 20px 0 0;
  z-index: 9;
  background-color: $white;
  box-shadow: 1px 1px 15px 0px #0000001a;

  .topContent {
    display: flex;
    align-items: center;
    gap: 10px;

    .text {
      font-size: 13px;
      color: $black;
    }

    .ratingContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 4px;
      margin-top: 4px;
    }

    .grayText {
      font-size: 10px;
      color: $darkGray;
    }
  }

  .buttonsContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }

  .installLink {
    padding: 6px 11px;
    font-size: 12px;
    font-weight: 600;
    background-color: $blue;
    color: $white;
    border-radius: 50px;
  }

  .closeButton {
    padding: 6px 18px;
    border: 1px solid $lightGray;
    border-radius: 50px;
    font-size: 12px;
  }
}
