@import "variables";
@import "mixins";

.body {
  position: absolute;
  inset: 0;
  max-height: 90vh;
  margin: auto;
  max-width: 90vw;
  border-radius: 20px;

  @include pc {
    width: 755px;
    max-height: 610px;
  }

  .modalInner {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;

    .header {
      padding-top: 16px;
      background-color: #eaf2fa;
      border-radius: 20px 20px 0 0;

      .title {
        font-size: 18px;
        font-weight: 600;
        line-height: 27px;
        text-align: center;
        color: $blue;

        @include pc {
          font-size: 22px;
          line-height: 33px;
        }
      }

      .description {
        width: 60%;
        margin: auto;
        padding: 8px 0px 16px;
        font-size: 12px;
        line-height: 18px;
        text-align: center;

        @include pc {
          font-size: 13px;
          line-height: 19.5px;
        }
      }
    }

    .closeButton {
      position: absolute;
      top: -12px;
      right: -18px;
      border: none;
      background: none;
      color: $white;
      font-size: 28px;
      font-weight: 200;
      cursor: pointer;
    }

    .contentWrap {
      @include typeformContentWrap;
      height: 100%;
      border: none;
      box-shadow: none;
    }
  }
  .primaryButton {
    margin: 10px auto;
    padding: 8px 0;
    width: 70%;
    max-width: 200px;
    color: $white;
    background-color: $blue;
    border: none;
    border-radius: 5px;

    @include pc {
      padding: 13px 0;
      margin: 20px auto;
    }
  }
}
