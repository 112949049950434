@import "variables";
@import "mixins";

.swiper-button-prev,
.swiper-button-next {
  background-color: rgba(255, 255, 255, 0.9) !important;
  height: 30px !important;
  width: 30px !important;
  top: 0 !important;
  bottom: 0 !important;
  margin: auto !important;
  // &.swiper-button-disabled {
  // }
  &:after {
    content: none !important;
  }
}
.swiper-button-prev {
  left: 0 !important;
  &:before {
    position: absolute;
    content: "";
    width: 12px;
    height: 12px;
    left: 12px;
    border: 2px solid;
    border-color: transparent transparent $blue $blue;
    transform: rotate(45deg);
  }
}
.swiper-button-next {
  right: 0 !important;
  &:before {
    position: absolute;
    content: "";
    width: 12px;
    height: 12px;
    right: 12px;
    border: 2px solid;
    border-color: $blue $blue transparent transparent;
    transform: rotate(45deg);
  }
}

.swiper-pagination-fraction {
  height: 30px !important;
  width: 50px !important;
  top: 0 !important;
  right: auto !important;
  bottom: auto !important;
  left: 0 !important;
  line-height: 30px !important;
  font-size: 12px !important;
  background-color: rgba(255, 255, 255, 0.9) !important;
}

@include sp() {
  .ReactModal__Overlay {
    z-index: 12;
    transform: translateX(100vw);
    transition: all 500ms;
  }
  .ReactModal__Overlay--after-open {
    transform: translateX(0px);
  }
  .ReactModal__Overlay--before-close {
    transform: translateX(100vw);
  }
}

.detailSwiperSlide2 {
  &:not(.swiper-slide-thumb-active) {
    opacity: 0.4;
  }
}
