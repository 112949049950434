@import "variables";
@import "mixins";

.bannerContainer {
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 0;
  width: 100%;
  padding: 10px;
  z-index: 10;
  border-bottom: 1px solid $lightGray;
  background-color: $superLightBlue2;

  .leftContent {
    display: flex;
    align-items: center;
    gap: 8px;

    .closeButton {
      padding: 4px;
    }

    .rating {
      display: flex;
      align-items: center;
      column-gap: 4px;
      margin-top: 2px;
    }
    .text {
      font-size: 11px;
      color: $black;
      font-weight: 600;
      white-space: pre-wrap;
    }

    .grayText {
      color: $darkGray;
      font-size: 10px;
    }
  }

  .installLink {
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 8px 12px;
    background-color: $blue;
    border-radius: 8px;

    .linkText {
      font-size: 11px;
      color: $white;
    }
  }
}
