.overlay {
  position: fixed;
  inset: 0px;
  background-color: rgba(0, 0, 0, 0);
  opacity: 0;
  transition: all 0.5s;
  transition-timing-function: ease-in-out;
  z-index: 99;
}

.overlayBefore {
  background-color: rgba(0, 0, 0, 0);
  opacity: 0;
  transition: all 0.5s;
}

.overlayAfter {
  background-color: rgba(0, 0, 10, 0.5);
  opacity: 1;
}

.bodyBefore {
  background-color: transparent;
  opacity: 0;
  transition: all 0.5s;
}

.bodyAfter {
  background-color: white !important;
  opacity: 1 !important;
}
