@import "variables";
@import "mixins";

.sideMenuWrapper {
  display: flex;
  overflow-y: scroll;
  outline: none;
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
}

.sideMenuBody {
  position: absolute;
  top: 0;
  right: 0;
  background-color: white;
  overflow-y: scroll;
  outline: none;
  height: 100%;
  width: 90%;
  max-width: $pc-sideMenu-max-width;
  overflow-y: scroll;
}

.sideMenuCloseIcon {
  position: fixed;
  top: 3px;
  right: 20px;
  color: white;
  font-size: 32px;
  font-weight: 200;
  line-height: 1;
  padding-left: 2px;
  cursor: pointer;

  @include pc {
    top: 5px;
    right: $pc-sideMenu-max-width + 10px;
    font-size: 40px;
  }
}

.sideMenuTitle {
  width: 100%;
  padding: 0 20px;
  background-color: $blue;
  color: white;
  font-weight: 600;
  font-size: 15px;
  line-height: 45px;
}

.sideMenuContentsList {
  li {
    width: 100%;
    border-bottom: 1px solid $lightGray;
    line-height: 45px;
    font-size: 14px;

    @include pc {
      transition: background-color $hover-transition;

      &:hover {
        background-color: $lightGray;
      }
    }

    a {
      display: flex;
      width: 100%;
      padding: 0 20px;
    }
  }

  .sideMenuContentItemWithCount {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .countWrapper {
      height: 24px;
      padding: 3px 8px;
      line-height: 1;
      border-radius: 100px;
      background-color: #2391dc1a;

      span {
        font-size: 12px;
        font-weight: 600;
        line-height: 18px;
        color: $blue;
      }
    }
  }
}

.sideMenuButtons {
  display: flex;
  gap: 10px;
  margin: 20px 20px;
  a {
    display: flex;
    flex-direction: column;
    justify-content: bottom;
    height: 80px;
    width: 100%;
    padding-bottom: 15px;
    border: 1px solid $lightGray;
    border-radius: 3px;
    font-size: 13px;

    @include pc {
      transition: background-color $hover-transition;

      &:hover {
        background-color: $lightGray;
      }
    }
    span {
      text-align: center;
    }
    &:before {
      content: "";
      display: block;
      margin: auto auto 5px auto;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }
    &.station:before {
      height: 21px;
      width: 16px;
      background-image: url("/static/svgs/top/station.svg");
    }
    &.area:before {
      height: 22px;
      width: 17px;
      background-image: url("/static/svgs/top/area.svg");
    }
  }
}

.searchItem {
  margin: 20px;

  .label {
    font-size: 15px;
    line-height: 22.5px;
  }
  .item {
    margin-top: 10px;
  }
}

.pastCriteria {
  display: inline-block;
  position: relative;
  width: 100%;
  padding: 20px;
  border: 1px solid $lightGray;
  border-radius: 3px;
  box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.02);

  @include pc() {
    transition: background-color $hover-transition;

    &:hover {
      background-color: $lightGray;
    }
  }

  &:after {
    content: "";
    height: 13px;
    width: 7px;
    position: absolute;
    top: 24px;
    right: 10px;
    bottom: 0;
    background-repeat: no-repeat;
    background-image: url("/static/svgs/common/arrow_right.svg");
  }
  .text {
    font-size: 13px;
    line-height: 20px;
    text-align: left;

    @include sp() {
      font-size: 12px;
      line-height: 20px;
    }
  }

  .textOverflow {
    max-width: 225px;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 314px;
  }
}
