@import "variables";
@import "mixins";

/*****************************
 * Implementation of Helpers *
 *****************************/

/* --------------------
  Responsible Helpers
-------------------- */
.is-pc-only {
  @include mq-down(sp) {
    display: none !important;
  }
}

.is-sp-only {
  @include mq-up(pc) {
    display: none !important;
  }
}

/* ------------------
  Font Size Helpers
------------------ */

@each $s in $font-sizes {
  .is-#{$s}px {
    font-size: #{$s}px !important;
  }
  .is-sp-#{$s}px {
    @include mq-down(sp) {
      font-size: #{$s}px !important;
    }
  }
}

/* --------------------
  Margin Size Helpers
-------------------- */
$margins: (
  t: top,
  r: right,
  b: bottom,
  l: left,
);

@each $s in $margin-sizes {
  @each $k, $v in $margins {
    .is-m#{$k}#{$s} {
      margin-#{$v}: #{$s}px !important;
    }
  }
}

@each $s in $margin-sizes {
  @each $k, $v in $margins {
    .is-p#{$k}#{$s} {
      padding-#{$v}: #{$s}px !important;
    }
  }
}

@each $k, $v in $margins {
  .is-m#{$k}a {
    margin-#{$v}: auto !important;
  }
}

.is-ma {
  margin: auto;
}

/* --------------
  Color Helpers
-------------- */
$colors: (
  red: $red,
  blue: $blue,
);

@each $k, $v in $colors {
  .has-color-#{$k} {
    color: #{$v} !important;
  }
}

/* --------------
  Other Helpers
-------------- */

.partition-2 {
  display: flex;
  @include pc() {
    & > div {
      width: 50%;
    }
  }
  @include sp() {
    flex-direction: column;
  }
}

.is-w100 {
  width: 100% !important;
}

.is-w100px {
  width: 100px !important;
}

.is-w150px {
  width: 150px !important;
}

.is-font-weight-300 {
  font-weight: 300 !important;
}

.is-font-normal {
  font-weight: 400 !important;
}

.is-font-bold {
  font-weight: 600 !important;
}

.is-line-height-2 {
  line-height: 2;
}

.hidden {
  display: none !important;
}

.pc-hidden {
  @include pc() {
    display: none !important;
  }
}

.flex {
  display: flex;
}

.center {
  margin-left: auto;
  margin-right: auto;
}

.white-space-normal {
  white-space: normal;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  clip: rect(1px 1px 1px 1px);
  overflow: hidden;
}

.divider {
  height: 1px;
  background-color: $lightGray;
  width: 100%;
}
