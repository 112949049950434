@import "variables";
@import "mixins";

html,
body {
  padding: 0;
  margin: 0;
  font-family:
    // https://yuheiy.hatenablog.com/entry/2017/09/04/210650 の記事を参考にsystem-uiが他のブラウザでも同様に動く&和文はHiragino Sansになるように指定
    // OS X Chrome（欧文）、Windows Chrome（和文・欧文）、Android Chrome（和文・欧文）
    system-ui,
    // OS X Safari（欧文）、iOS Safari（欧文）、OS X Firefox（欧文）
    -apple-system,
    // OS X El Capitan及びとiOS 9以降（和文）
    "Hiragino Sans",
    // OS X El Capitan及びiOS 9以前 (欧文)
    "Helvetica Neue",
    // OS X El Capitan及びiOS 9以前 (欧文)
    "Hiragino Kaku Gothic ProN",
    // Windows 10 Chrome以外（和文・欧文）
    "Yu Gothic UI",
    // Windows 8.1以前 Chrome以外（欧文）
    "Segoe UI",
    // Windows 8.1以前 Chrome以外（和文）（Windows 7のIE11用の指定）
    "Meiryo",
    // その他
    sans-serif;
  scroll-behavior: auto !important;

  @include pc() {
    scroll-padding-top: 80px;
  }
  @include sp() {
    scroll-padding-top: 70px;
  }
}
a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  font-weight: 400;
  color: $black;
  letter-spacing: 0.5px;
}

svg {
  margin-top: auto;
  margin-bottom: auto;
}

// 画像の保存禁止対応（see: AIRDOOR-830）
img {
  pointer-events: none;
}

hr {
  height: 0px;
  border: none;
  margin: 0;
  padding: 0;
}

.l-basic,
.ReactModal__Overlay {
  font-weight: 300;

  span {
    font-weight: inherit;
    color: inherit;
  }

  H2 {
    display: flex;
    flex-direction: row;
    font-weight: inherit;
    @include pc() {
      margin-top: 75px;
      font-size: 25px;
      letter-spacing: 2px;
    }
    @include sp() {
      margin-top: 55px;
      line-height: 20px;
      font-size: 20px;
      letter-spacing: 0.5px;
    }
    a {
      display: flex;
      flex-direction: row;
      margin-left: auto;
      margin-top: auto;
      margin-bottom: auto;
      color: $blue;
      letter-spacing: 0.5px;
      white-space: nowrap;

      @include pc() {
        line-height: 37.5px;
        font-size: 12px;
      }
      @include sp() {
        line-height: 20px;
        font-size: 10px;
      }

      &:after {
        content: "";
        display: block;
        border: 1px solid;
        border-color: $blue $blue transparent transparent;
        transform: rotate(45deg);
        @include pc() {
          width: 10px;
          height: 10px;
          margin: auto auto auto 3px;
        }
        @include sp() {
          width: 8px;
          height: 8px;
          margin: auto auto auto 2px;
        }
      }
    }
  }

  input[type="checkbox"] {
    display: none;
  }
  input[type="checkbox"] {
    & + label {
      display: flex;
      align-items: center;
      font-size: 12px;
      line-height: 16px;
      cursor: pointer;
      &:before {
        content: "";
        width: 16px;
        height: 16px;
        margin: auto 8px auto 0;
        border: 1px solid $gray;
        border-radius: 4px;
        background-color: white;
        cursor: pointer;
      }
      &.-mr20:before {
        margin-right: 20px;
      }
      &.-middle {
        font-size: 15px;
        line-height: 20px;
        &:before {
          height: 20px;
          width: 20px;
        }
      }
      &.-big {
        font-size: 20px;
      }
    }
    &:checked + label:before {
      content: "✓";
      background-color: $blue;
      border: none;
      font-size: 14px;
      line-height: 16px;
      color: white;
      text-align: center;
    }
  }

  input[type="text"],
  select {
    height: 40px;
    border: 1px solid $lightGray;
    border-radius: 5px;
    padding: 0 10px;
    font-size: 12px;
    line-height: 35px;
  }

  .sel {
    position: relative;
    &:after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 5px;
      right: 12px;
      width: 8px;
      height: 8px;
      margin: auto;
      border: 1px solid;
      border-color: transparent transparent $black $black;
      transform: rotate(-45deg);
    }
    select {
      width: 100%;
      appearance: none;
      cursor: pointer;
    }
    &.is-active {
      &:after {
        border-color: transparent transparent white white;
      }
      select {
        background-color: $blue;
        border: 1px solid $blue;
        color: white;
      }
    }
  }
}

.container {
  @include pc() {
    width: $pc-width;
    margin: auto;
  }
  @include sp() {
    padding: 0 $sp-padding-width;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}
