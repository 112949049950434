@import "variables";
@import "mixins";

.body {
  width: 100%;
  background-color: $blue;
  padding-bottom: 50px;

  @include pc {
    margin-top: auto;
    padding-top: 70px;
  }
  @include sp {
    padding-top: 50px;
  }
}

.logo {
  position: relative;
  display: block;
  height: 36px;
  width: 100%;
  @include pc() {
    margin-left: 30px;
    margin-bottom: 40px;
  }
  @include sp() {
    margin-left: 5px;
    margin-bottom: 50px;
  }
}

.list {
  margin-bottom: 60px;
  H3 {
    font-size: 15px;
    line-height: 50px;
    color: white;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
  }
  li {
    height: 30px;
    margin-bottom: 10px;
    margin-right: 8px;
    padding: 0 8px;
    border-radius: 5px;
    background-color: white;
    line-height: 30px;
    font-size: 12px;
    color: $black;
  }
}

.links {
  display: flex;
  margin-bottom: 40px;
  ul {
    display: flex;
    flex-direction: column;
    width: 160px;
    padding-left: 20px;
    border-left: 1px solid white;
  }
  ul:nth-child(1) {
    height: 118px;
  }
  ul:nth-child(2) {
    height: 182px;
  }
  li {
    color: white;
    font-size: 12px;
    line-height: 18px;
    + li {
      margin-top: 15px;
    }
  }
}

.sns {
  display: flex;
  margin-top: 50px;

  ul {
    display: flex;
    align-items: center;
  }

  @include pc {
    ul {
      justify-content: left;
    }
  }
  @include sp {
    flex-direction: column;
    ul {
      justify-content: center;
    }
  }

  li {
    background-size: contain !important;
    cursor: pointer;
    & + li {
      margin-left: 16px;
    }

    a {
      position: relative;
      display: block;
      width: 100%;
      height: 100%;
    }
  }
  .c {
    color: white;
    font-size: 10px;
    margin-top: auto;
    margin-left: 30px;
    @include sp {
      margin-top: 50px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.survey {
  margin-bottom: 8px;
  p {
    color: $white;
    font-size: 12px;
  }
  .thankyou {
    font-size: 16px;
    font-weight: 600;
  }
  .description {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  iframe {
    width: 100%;
    height: 500px;
    border: 0;
    border-radius: 20px;
    @include sp {
      height: 480px;
    }
  }
  .contentWrap {
    @include typeformContentWrap;
  }
}

.isMorePaddingBottom {
  padding-bottom: 160px;
}
