@import "variables";

.heartWithCount {
  display: inline-block;
  position: relative;
  width: 22px;
  height: 18px;

  .heart {
    position: absolute;
    top: 0;
    left: 0;
  }

  .number {
    position: absolute;
    top: 5px;
    left: 13px;
    background-color: $blue;
    border: solid 1px $white;
    border-radius: 50px;
    color: $white;
    font-size: 9px;
    line-height: 10px;
    padding: 1px 5px;
  }
}
